import styled from "styled-components";

export const SoundHeadDiv = styled.div`
  --accentColor: var(--red);
  max-width: var(--width);
  width: 100%;
  height: 975px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
  justify-content: center;
  & > img,
  & > .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    width: 100% !important;
    object-fit: cover;
    height: 750px !important;
  }
  & > .placeholder {
    background-color: var(--accentColor);
    position: absolute !important;
    top: 0;
    width: 100% !important;
    object-fit: cover;
    height: 750px !important;
  }
`;

export const SoundsHeadTextDiv = styled.div`
  --accentColor: ${(props) => props.color};
  --textColor: ${(props) => (props.light ? "var(--black)" : "var(--white)")};
  --backgroundColor: ${(props) =>
    props.light ? "var(--white)" : "var(--black)"};
  position: absolute;
  bottom: 0;
  background-color: var(--backgroundColor);
  color: var(--textColor);
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 25px 0;
  & > div {
    display: flex;
    justify-content: space-between;
    width: 620px;
    margin-left: auto;
    margin-right: auto;
    & + div {
      flex-direction: column;
    }
  }
  & h4 {
    color: var(--accentColor);
  }
  & a {
    color: var(--accentColor);
  }
  & p {
    margin-top: 25px;
    margin-bottom: 0;
    width: 620px;
    margin-left: auto;
    margin-right: auto;
    & + aside {
      margin-top: 25px;
    }
  }
  & .desktophidden {
    display: none;
  }
  @media (max-width: 819px) {
    --outerMargin: 40px;
    max-width: 375px;
    padding: 0 var(--outerMargin) 20px var(--outerMargin);
    display: flex;
    flex-direction: column;
    & .mobilehidden {
      display: none;
    }
    & .desktophidden {
      display: block;
    }
    & p + aside {
      margin-top: 25px;
    }
  }
  @media (max-width: 767px) {
    --outerMargin: 10px;
    padding-top: 10px;
    & > div {
      max-width: 100%;
      & > p {
        max-width: 100%;
      }
    }
  }
`;
