import styled from "styled-components";

export const PodcastDiv = styled.div`
  background-color: var(--red);
  color: var(--white);
  max-width: var(--width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  & > div:first-of-type {
    display: flex;
    justify-content: space-between;
    & div {
      width: 100px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 7px 17px;
      & svg {
        display: block;
        height: 14px;
        width: 14px;
      }
      & a {
        color: var(--white);
        & h6 {
          display: inline-flex;
          align-items: center;
          & svg {
            margin-left: 4px;
            padding-bottom: 1px;
            height: 10px;
            width: 10px;
          }
        }
      }
    }
    & div:nth-of-type(2) {
      flex: 2;
      justify-content: flex-start;
    }
    & div + div {
      border-left: 1px solid white;
    }
    & + div {
      border-top: 1px solid var(--white);
      & a {
        display: flex;
        color: var(--white);
        & > div {
          min-width: 100px;
          height: 30px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 7px 17px;
          & h6 {
            text-align: center;
          }
          & + div {
            border-left: 1px solid transparent;
          }
        }
        &:hover h6 {
          font-weight: bold;
        }
      }
    }
  }
`;
