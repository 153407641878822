import React from "react";
import PropTypes from "prop-types";

const Pause = ({ color }) => (
  <svg width={28} height={18} fill="none" viewBox="0 0 28 18">
    <path fill={color || "#fff"} d="M7 2H12V16H7zM16 2H21V16H16z" />
  </svg>
);

export default Pause;

Pause.propTypes = {
  color: PropTypes.string,
};
