import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import MiscSmallSocial from "./../icons/MiscSocialSmall";
import { SoundHeadDiv, SoundsHeadTextDiv } from "./elements";
import { H4BigStrong, H4Big, P } from "./../Typography";

const SoundsHead = ({
  subTitle,
  title,
  date,
  description,
  link,
  backgroundImage,
}) => (
  <SoundHeadDiv>
    {backgroundImage && backgroundImage.wide ? (
      backgroundImage.wide.childImageSharp ? (
        <Img fixed={backgroundImage.wide.childImageSharp.fixed} />
      ) : (
        <img src={backgroundImage.wide.publicURL} />
      )
    ) : (
      <div className="placeholder" />
    )}
    <SoundsHeadTextDiv color={"var(--red)"} light>
      <div>
        <div>
          <H4BigStrong>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          </H4BigStrong>
          <H4BigStrong>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {subTitle}
            </a>
          </H4BigStrong>
          {date ? <H4Big>{date}</H4Big> : null}
        </div>
        {/* <MiscSmallSocial color={"var(--red)"} className="mobilehidden" /> */}
      </div>
      <div>
        <P>{description}</P>
        <MiscSmallSocial color={"var(--red)"} />
      </div>
    </SoundsHeadTextDiv>
  </SoundHeadDiv>
);

export default SoundsHead;

SoundsHead.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};
