import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "./../components/Layout";
import SoundsHead from "./../components/SoundsHead";
import SoundBar from "./../components/SoundBar";
import ArticleFilter from "./../components/ArticleFilter";
import { getDate } from "./../modules/time";
import { SoundCloudEmbed } from "./../components/RichContent";

const SoundCloudWrapper = styled.div`
  display: flex;
  width: var(--width);
  min-width: var(--width);
  margin-left: auto;
  margin-right: auto;
  margin-top: -25px;
  & > div {
    width: 100vw;
    max-width: 100vw;
  }
`;

const ArticleSoundsPageTemplate = ({ data, pageContext }) => {
  const {
    title,
    headerImage,
    headerImageMobile,
    subtitle,
    editorialDate,
    editorialDarkMode,
    editorialShortDescription,
    editorialSoundcloudUrl,
  } = data.craftEntryInterface;
  const myFileUrl =
    data.craftEntryInterface.editorialSoundFile &&
    data.craftEntryInterface.editorialSoundFile.length
      ? data.craftEntryInterface.editorialSoundFile[0].url
      : null;
  const myColor = "var(--red)";
  const textColor = "var(--white)";
  const backgroundColor = "var(--black)";
  return (
    <Layout
      footerColor={myColor}
      backgroundColor={backgroundColor}
      headerColor={editorialDarkMode ? "var(--dark)" : textColor}
      skipHeader
      SEOIsArticle
      title={title}
      SEODescription={editorialShortDescription}
      SEOImageURL={
        headerImage &&
        headerImage.length &&
        headerImage[0].SEOImage &&
        headerImage[0].SEOImage.childImageSharp
          ? `https://alserkal.online${headerImage[0].SEOImage.childImageSharp.fixed.src}`
          : null
      }
    >
      <SoundsHead
        title={title}
        subTitle={subtitle}
        date={getDate(editorialDate)}
        description={editorialShortDescription}
        link={myFileUrl}
        backgroundImage={
          headerImage && headerImage.length ? headerImage[0] : null
        }
      />
      {editorialSoundcloudUrl ? (
        <SoundCloudWrapper>
          <SoundCloudEmbed soundCloudUrl={editorialSoundcloudUrl} />
        </SoundCloudWrapper>
      ) : (
        <SoundBar
          podcastList={[
            {
              code: title,
              name: subtitle,
              length: 0,
              file: myFileUrl,
              soundcloudUrl: editorialSoundcloudUrl,
            },
          ]}
        />
      )}
      <ArticleFilter backgroundColor={"var(--black)"} defaultType={"sounds"} />
    </Layout>
  );
};

export default ArticleSoundsPageTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    craftEntryInterface(id: { eq: $id }) {
      id
      ... on Craft_articles_sounds_Entry {
        title
        headerImage {
          ... on Craft_editorialAssets_Asset {
            SEOImage: localFile {
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
            wide: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440, height: 750, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        headerImageMobile{ 
          ... on Craft_editorialAssets_Asset {
            tallImage: localFile {
              publicURL
              childImageSharp {
                fixed(height: 550) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        editorialDarkMode
        editorialShortDescription
        editorialDate
        editorialSoundcloudUrl
        editorialSoundFile {
          url
        }
        subtitle
      }
    }
  }
`;
