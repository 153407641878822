import React from "react";
import PropTypes from "prop-types";
import { PodcastDiv } from "./elements";
import { H6Medium } from "./../Typography";
import Pause from "./../icons/Pause";
import Play from "./../icons/Play";
import ArrowDown from "./../icons/ArrowDown";
import ArrowUp from "./../icons/ArrowUp";
import ArrowRight from "./../icons/ArrowRight";

const makeTime = (time) =>
  String(
    Math.floor(time / 3600) +
      ":" +
      Math.floor((time % 3600) / 60) +
      ":" +
      Math.floor(time / 60)
  );
/**
 *
 * **NOTE:** This needs a play icon! It's designed with a pause icon. I've used ArrowRight for the moment.
 *
 * This is not currently taking any MP3 files or actually doing anything when it's playing, though the hooks are there.
 *
 */
const SoundBar = ({ podcastList, collapsed }) => {
  const [positionInList, setPositionInList] = React.useState(0);
  const [currentPosition /*, setCurrentPosition*/] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isListShowing, setIsListShowing] = React.useState(false);
  return (
    <PodcastDiv>
      <div>
        <div>
          <H6Medium>{podcastList[positionInList].code}</H6Medium>
        </div>
        <div>
          <H6Medium>{podcastList[positionInList].name}</H6Medium>
        </div>
        <div>
          <H6Medium>{makeTime(currentPosition)}</H6Medium>
        </div>
        <div>
          {isPlaying ? (
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                setIsPlaying(false);
              }}
            >
              <Pause />
            </a>
          ) : (
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                setIsPlaying(true);
              }}
            >
              <Play />
            </a>
          )}
        </div>
        {collapsed ? (
          <div>
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                setPositionInList(
                  positionInList + 1 < podcastList.length
                    ? positionInList + 1
                    : 0
                );
              }}
            >
              <H6Medium>
                NEXT <ArrowRight />
              </H6Medium>
            </a>
          </div>
        ) : (
          <div>
            {isListShowing ? (
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsListShowing(false);
                }}
              >
                <ArrowUp />
              </a>
            ) : (
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsListShowing(true);
                }}
              >
                <ArrowDown />
              </a>
            )}
          </div>
        )}
      </div>
      {isListShowing ? (
        <div>
          {podcastList.map((entry, index) =>
            index === positionInList ? null : (
              <a
                key={index}
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setPositionInList(index);
                  setIsListShowing(false);
                }}
              >
                <div>
                  <H6Medium>{entry.code}</H6Medium>
                </div>
                <div>
                  <H6Medium>{entry.name}</H6Medium>
                </div>
              </a>
            )
          )}
        </div>
      ) : null}
    </PodcastDiv>
  );
};

export default SoundBar;

SoundBar.propTypes = {
  podcastList: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      length: PropTypes.number,
      file: PropTypes.string,
    })
  ),
  collapsed: PropTypes.bool,
};
