import React from "react";
import PropTypes from "prop-types";

const Play = ({ color }) => (
  <svg width={28} height={18} fill="none" viewBox="0 0 28 18">
    <path fill={color || "#fff"} d="M21 9L7 17V1l14 8z" />
  </svg>
);

export default Play;

Play.propTypes = {
  color: PropTypes.string,
};
